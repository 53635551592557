@value primaryColor from '../../styles/colors.css';

.contentContainer {
  margin-top: 77px;
  margin-left: 250px;
  background: white;
  border-top-left-radius: 4px;
  padding-top: 32px;
  padding-left: 64px;
  padding-right: 32px;
  height: calc(100vh - 77px);
  overflow: scroll;
}

.bookingsCalendar {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  width: 900px;
}

@media only screen and (max-width: 1100px) {
  .bookingsCalendar {
    width: 650px;
  }
}

/* Banner */
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background-color: #eeeeee;
}

.datePickerContainer {
  width: 200px;
  margin: auto;
  padding: 8px 0;
}

.datePicker {
  width: 200px;
  text-align: center;
  border: none;
  border-radius: 3px;
  background-color: #eeeeee;
  font-weight: bold;
  font-size: 15px;
}

.datePicker:hover {
  cursor: pointer;
}

.datePicker:focus {
  outline: none;
}

.switchWeek {
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  width: calc(100% / 3);
  height: 75%;
  margin: auto;
}

.switchWeek:focus {
  outline: none;
}

.switchWeek:hover {
  cursor: pointer;
}

.prevWeek {
  composes: switchWeek;
  text-align: left;
}

.nextWeek {
  composes: switchWeek;
  text-align: right;
}

.weekSwitchText {
  flex: 2;
  padding: 0 8px;
  height: 100%;
  margin: auto
}

.arrowIcon {
  height: 100%;
  margin: auto;
  width: 40px;
}

.disabled {
  color: grey;
}

.disabled:hover {
  cursor: default;
}

/* Bookings List */
.bookingsContainer {
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding-top: 8px;
  min-height: 400px;
  max-height: 500px;
  width: 100%;
  overflow: scroll;
}

.calendarBackButton {
  margin-bottom: 16px;
  cursor: pointer;
}

.column {
  width: 10%;
  min-width: 75px;
}

.date {
  margin-bottom: 16px;
  text-align: center;
}

.booking {
  display: flex;
  margin-bottom: 16px;
  padding: 0px;
  width: 100%;
  height: 50px;
  text-align: center;
}

.noBooking {
  margin: auto;
}

.noBookingsLink {
  color: primaryColor;
}

@media only screen and (max-height: 799px) {
  .bookingsContainer {
    min-height: 322px;
    max-height: 322px;
  }
}

@media only screen and (max-height: 700px) {
  .bookingsContainer {
    min-height: 256px;
    max-height: 256px;
  }
}


/* Footer */
.bookingsFooter {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  background: #eeeeee;
  width: 100%;
}

.selectedBookingBox {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.removeBox {
  display: flex;
  align-items: center;
}

.removeBox:hover {
  cursor: pointer;
  color: #888888;
}

.removeBox:focus {
  outline: none;
}

.confirm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 35%;
}

.calendarIcon {
  margin-right: 8px;
}

.calendarIconBox {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.selectedBooking {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1280px) {
  .detailsBoxTitle {
    font-size: 18px;
  }
}

/* Review and Confirm */

.dialogConfirmation {
  padding: 30px;
  width: 450px;
}

.dialogHeader {
  text-align: center;
  margin-bottom: 40px;
}

.dialogHeaderBack {
  position: absolute;
  top: 30px;
  left: 30px;
  font-weight: bold;
}

.dialogHeaderBack:hover {
  cursor: pointer;
}

.dialogHeaderBack:focus {
  outline: none;
}

.dialogHeaderTitle {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 250px;
  margin: auto;
}

.dialogHeaderText {
  font-weight: 700;
}

.dialogRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto;
}

.dialogColLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 10px;
}

.dialogColRight {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.dialogFooter {
  width: 350px;
  margin: 40px auto 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dialogFooterText {
  margin-bottom: 15px;
}

.dialogSuccess {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 250px;
  padding: 25px 0px;
}

.dialogSuccessItem {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

.dialogSuccessItem:last-child {
  padding-bottom: 0;
}

.dialogSuccessText {
  margin: auto;
}

/* Schedule Selector */
.scheduleContainer {
  display: flex;
  flex-direction: column;
  max-width: calc(750px + 64px);
}

.selectorContainer {
  margin-bottom: 32px;
}

.scheduleSubtext {
  margin-top: 16px;
}

.scheduleSelect {
  margin-right: 32px;
  width: 250px;
}

.scheduleSelect:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1100px) {
  .scheduleContainer {
    width: 650px;
  }

  .scheduleSelect{
    width: 194px
  }
}

.scheduleApptTime {
  opacity: 0;
  transition: opacity .25s ease-in;
  pointer-events: none;
  padding-top: 8px;
  color: rgba(0, 0, 0, 0.35);
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.scheduleApptTimeDate {
  text-transform: capitalize;
}

.scheduleApptTimeShowing {
  opacity: 1;
}
