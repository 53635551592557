@value gray from '../../styles/colors.css';

.contentContainer {
  margin-top: 77px;
  margin-left: 250px;
  background: white;
  border-top-left-radius: 4px;
  padding-top: 32px;
  padding-left: 64px;
  padding-right: 32px;
  height: calc(100vh - 77px);
  overflow: scroll;
}

.content {
  display: flex;
}

.infoColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 450px;
}

.updateColumn {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex: 2;
}

.updateInfoColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.infoColumnTitle {
  margin-bottom: 16px;
  width: 100%;
}

.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 300px;
}

.infoBoxBody {
  margin: 12px 0 18px;
  background: rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
  width: 100%;
}

.updateField {
  margin: 6px 0 18px;
  width: 100%;
}

.button {
  max-width: 300px;
}
