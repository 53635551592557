@value success, danger, white from '../styles/colors.css';

.successIcon {
  color: success;
}

.dangerIcon {
  color: danger;
}

.passVisText {
  margin-right: 2px;
}

.spinnerContainer {
  position: fixed;
  z-index: 1199;
  background: rgba(255, 255, 255, .5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinnerContAbsolute {
  composes: spinnerContainer;
  position: absolute;
}
