@value gray from '../../styles/colors.css';

.contentContainer {
  margin-top: 77px;
  margin-left: 250px;
  background: white;
  border-top-left-radius: 4px;
  padding-top: 32px;
  padding-left: 64px;
  padding-right: 32px;
  height: calc(100vh - 77px);
  overflow: scroll;
}

.changePassInput {
  width: 100%;
}

.settingsContainer {
  display: flex;
  flex-wrap: wrap;
  /* calc for spacing the nav and the main container */
  margin-top: calc(78px + 32px);
  /* calc for spacing the sidebar and the main container */
  margin-left: calc(250px + 64px);
}

.settingBackButton {
  margin-bottom: 16px;
}

.settingsTitle {
  width: 100%;
}

.MFATitle {
  padding-top: 16px;
  padding-bottom: 16px;
}

.appButton {
  border-radius: 0;
  margin-top: 16px;
  width: 100%;
  max-width: 256px;
}

.fullWidth {
  width: 100%;
  max-width: 850px;
}

.flexContainer {
  display: flex;
}

.halfWidthContainer {
  margin: 16px;
  width: calc(50% - 32px);
}
