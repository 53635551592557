@value primaryColor from '../../styles/colors.css';

.contentContainer {
  margin-top: 77px;
  margin-left: 250px;
  background: white;
  border-top-left-radius: 4px;
  padding-top: 32px;
  padding-left: 64px;
  padding-right: 32px;
  height: calc(100vh - 77px);
  overflow: scroll;
}

.portalContainer {
  padding-bottom: 16px;
}

.appointmentInfoCard {
  margin-bottom: 12px;
}

.appointmentContainer {
  padding-bottom: 24px;
  width: 900px;
}

.apptContDivide {
  margin-bottom: 32px;
}

.cardHeader {
  font-size: 18px;
}

.cardTooltip {
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 8px;
}

.cardColCont {
  padding-left: 32px;
  padding-bottom: 16px;
  padding-right: 16px;
}

.apptCardInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
}

.apptCardLink {
  composes: apptCardInfo;
  color: primaryColor;
}

.apptCardText {
  margin-left: 8px;
}

.apptContSubHead {
  margin: 12px 0;
}

.noPatients {
  background: rgba(59, 177, 219, .15);
  padding: 8px 16px;
  color: black;
  border-radius: 3px;
}

.reschedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reschedButton {
  margin-right: 8px;
}

@media only screen and (max-width: 1100px) {
  .appointmentContainer {
    width: 650px;
  }
}
