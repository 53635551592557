@value gray, white, success, danger, primaryColor from '../../styles/colors.css';

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodyContainer {
  margin-bottom: 16px;
  width: 100%;
  max-width: 500px;
}

.bodyInput {
  padding-bottom: 8px;
  width: 100%;
}

.centerTitle {
  composes: flex;
  align-items: flex-end;
  margin-bottom: 32px;
  width: 100%;
  max-width: 1024px;
  min-height: 230px;
  text-align: center;
}

.dotStepper {
  background: white;
}

.footer {
  composes: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.footerSignInText {
  padding: 16px 0;
  color: gray;
  font-size: 18px;
}

.termsCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  width: 100%;
}

.legalToggle {
  cursor: pointer;
  color: primaryColor;
}

.legalToggle:hover {
  text-decoration: underline;
}

.legalCheckBox {
  margin-right: 8px;
  padding: 0;
}

.authPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 16px 16px;
  min-height: 100vh;
  width: 50vw;
  max-width: 1024px;
}

.loginFieldsContainer {
  display: block;
  width: 100%;
  max-width: 500px;
}

.textObject {
  display: block;
  padding: 1rem 0;
  cursor: pointer;
}

.textObject:hover {
  text-decoration: underline;
  color: primaryColor;
}

.authSubtext {
  margin-bottom: 24px;
}

.appButton {
  width: 100%;
  max-width: 256px;
}

.appButtonSpaced {
  composes: appButton;
  margin-top: 8px;
}

@media screen and (max-width: 1024px) {
  .authPage {
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px) {
  .JumboText {
    font-size: calc(16px + 15vw);
  }
}
