@value black, gray, white, primaryColor, backgroundColor from '../../styles/colors.css';

.navContainer {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1200;
  background: backgroundColor;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  padding-left: 314px;
  width: 100%;
}

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1201;
  background: backgroundColor;
  width: 250px;
  height: 100vh;
}

.sideHeader {
  position: relative;
  z-index: 99;
  width: 100%;
  height: 81px;
}

.sideDropControl {
  position: absolute;
  display: flex;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background: white;
  box-shadow: 0 8px 10px 3px rgba(0,0,0,.02);
  z-index: 9;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  cursor: pointer;
}

.sideDropControlDisabled {
  cursor: auto;
}

.dropDownOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.dropDownOverlayActive {
  pointer-events: all;
}

.dropDown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 8px 20px 7.5px rgba(0,0,0,.15);
  transform: translateX(-50%) rotateX(10deg) skewX(1deg);
  transition: transform .225s ease, opacity .225s ease;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  width: calc(100% - 16px);
  overflow: hidden;
  cursor: pointer;
}

.dropDownShowing {
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: all;
}

.dropDownItem {
  padding: 16px 0;
  color: black;
  text-align: center;
  transition: background .25s ease-in;
  text-transform: capitalize;
  cursor: pointer;
}

.dropDownItem:hover {
  background: #e5f4fa;
}

.dropDownItem:last-child {
  border-bottom: none;
}

.dropDownItemAlt {
  background: #f8f8f8;
}

.dropDownItemDisabled {
  color: #878787;
}

.sideBody {
  overflow: scroll;
  padding: 32px 16px 16px;
  height: calc(100vh - 158px);
}

.sideFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 81px;
}

.sideLocation {
  border-radius: 4px;
  background: white;
  box-shadow: 0 8px 10px 3px rgba(0,0,0,.02);
  margin-top: 16px;
  padding: 8px;
}

.sideTitle {
  font-weight: bold;
}

.side p {
  padding-left: 8px;
  margin: 8px 0;
  font-size: 14px;
}

.side a {
  padding-left: 8px;
  color: black;
  font-size: 14px;
}

.side a:hover {
  text-decoration: underline;
  color: primaryColor;
}

.navLink {
  color: black;
  padding: 0;
}

.navTool {
  composes: navLink;
  display: inline-block;
  margin-left: 16px;
}

.navTool:hover {
  background-color: transparent;
  color: primaryColor;
}

.navToolLabel {
  font-size: 15px;
  margin-right: 2px;
}

.patientTool {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  padding: 12px 0 2px;
  color: black;
}

.patientToolDisabled {
  pointer-events: none;
  color: rgba(20, 20, 20, .5);
}

.patientTool.active {
  border-bottom-color: primaryColor;
  pointer-events: none;
  color: primaryColor;
}
