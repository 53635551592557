body {
  font-size: 15px;
  font-family: 'Open Sans', Arial, Sans-Serif;
  background-color: #f1f6fb;
  color: #141414;
}

a {
  text-decoration: none;
  color: #3BB1DB;
}

p {
  margin: 0;
}

.JumboText {
  font-size: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Raleway', Arial, Sans-Serif;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
  font-weight: 300;
}

h6 {
  font-size: 17px;
  font-weight: 500;
}

.pre-wrap-white-space {
  white-space: pre;
}
